<template>
  <v-layout pa-2 class="question" mt-5 wrap>
    <v-container >
      <v-flex xs-12>
        <v-select
          :items="selection"
          :menu-props="{ maxHeight: '400' }"
          item-text="text"
          item-value="id"
          required
          @change="addToSelected($event)"
          :label="vraag.omschrijving"
          :reset-on-options-change='true'
        ></v-select
      ></v-flex>
      <v-flex v-if="selectedList.length>0">
        <v-list dense>
          <v-list-item v-for="s in selectedList" :key="s.id">
       

            <v-list-item-content>
              <v-list-item-title>{{ s.text }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon>
              <v-icon @click="deleteItem(s)">mdi-delete</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
        <i>U kunt meerdere items toevoegen</i>
      </v-flex>
      <v-flex v-else>
                <div pa-8 class="text-center">{{ vraag.toelichting }}</div>
            </v-flex>
    </v-container>

  </v-layout>
</template>

<script>
export default {
  props: { enums: Array, value: { type: Array, default: () => [] }, vraag: Object },
  methods: {
    addToSelected(item) {
      this.selected.push({ id: item });
      this.submitChages();

    },
    submitChages() {
      this.$emit("answerChanged", this.selected);
    },
    deleteItem(item) {
      const index = this.selected.findIndex(
        (element) => element.id === item.id
      );

      if (index > -1) {
        this.selected.splice(index, 1);
      }

      this.submitChages();
    },
  },
  data: () => ({
    selected: [],
  }),
  computed: {
    selection() {
      return this.enums.filter(
        (x) => !this.selected.some((y) => y.id === x.id)
      );
    },
    selectedList() {
      return this.selected.map((v) => ({
        ...this.enums.find((y) => y.id === v.id),
        ...v,
      }));
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: function (c) {
        if (c) {
          this.selected = [...c];
        }
      },
    },
  },
};
</script>